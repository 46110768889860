import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`relative mb-90 lg:mb-195`;

const Text = tw(motion.div)`
  hidden
  md:block md:absolute md:top-50 md:left-330 md:w-400 md:text-second md:text-md md:leading-30
  md:-tracking-027
  lg:top-145 lg:left-480 lg:w-500
  xl:left-548
`;

const Mockup = tw(motion.div)`
  relative -left-128 w-630
  sm:left-0
  md:-left-40
  lg:-left-155 lg:w-1215
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden
    md:block md:absolute md:bottom-150 md:right-0 md:w-100
    lg:bottom-450 lg:-right-100 lg:w-155
    xl:-right-10
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  md:block md:absolute md:-bottom-20 md:-left-20 md:w-180
  lg:-bottom-40 lg:-left-40 lg:w-380
`;

const EurobankInformation = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      mockup: file(relativePath: { eq: "cases/eurobank-mockup2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 150, sectionStart + 500], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 150, sectionStart + 500], [120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Mockup style={ width >= 1024 && { opacity }}>
        <Img fluid={data.mockup.childImageSharp.fluid} />
      </Mockup>
      <Text style={ width >= 1024 && { opacity, x }}>
        {t('Cases:Eurobank:Information architecture')}
      </Text>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
    </Wrapper>
  );
};

export default EurobankInformation;
