import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { up } from 'styled-breakpoints';
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`relative`;

const Background = styled(fullWidthBox)`
  background-image: linear-gradient(180deg,#FFF 27px,#F2FBF1 27px,#F2FBF1 249px,#FFF 249px);
  ${tw`sm:-left-640 md:-left-580 lg:-left-450 xl:-left-320`}
  
  ${up('sm')} {
    background-image: linear-gradient(180deg,#fff 37px,#F2FBF1 38px,#F2FBF1 347px,#FFF 348px);
  }

  ${up('md')} {
    background-image: linear-gradient(180deg,#fff 37px,#F2FBF1 38px,#F2FBF1 463px,#FFF 463px);
  }
  
  ${up('lg')} {
    background-image: linear-gradient(180deg,#fff 62px,#F2FBF1 63px,#F2FBF1 578px,#FFF 579px);
  }
  
  ${up('xl')} {
    background-image: linear-gradient(180deg,#fff 78px,#F2FBF1 79px,#F2FBF1 722px,#FFF 723px);
  }
`;

const Mockup = tw(motion.div)`
  relative left-320 w-550 mb-30
  sm:left-1/2 sm:w-768 sm:transform sm:-translate-x-1/2
  md:w-1024
  lg:w-1280 lg:mb-0
  xl:w-1600
`;

const Illustration = tw(motion.div)`
  absolute top-200 left-225 w-140
  sm:top-250 sm:left-380 sm:w-220
  md:top-350 md:left-500 md:w-300
  lg:left-700 lg:w-470
  xl:top-530 xl:left-870
`;

const Text = styled(motion.div)`
  ${tw`
    w-210 ml-45 mb-55 text-second leading-24 -tracking-024
    sm:w-auto sm:ml-30
    lg:relative lg:ml-0 lg:mb-235 lg:text-2xl lg:text-primary lg:leading-52
  `}

  br {
    ${tw`hidden sm:block`}
  }
`;

const DetailsWrapper = tw.div`
  absolute -top-20 left-360 w-140 transform -scale-x-100
  sm:left-460 sm:w-200
  md:left-580
  lg:-top-30 lg:left-auto lg:-right-120 lg:w-330
`;

const EurobankMockup = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      mockup: file(relativePath: { eq: "cases/eurobank-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      illustration: file(relativePath: { eq: "illustration-blue.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [0, 1]);
  const opacityIllustration = useTransform(scroll, [sectionStart + 600, sectionStart + 1000], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 600, sectionStart + 1000], [140, 0]);
  const y = useTransform(scroll, [sectionStart + 900, sectionStart + 1400], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 900, sectionStart + 1400], [-9, 0]);
  const opacityText = useTransform(scroll, [sectionStart + 900, sectionStart + 1400], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Background>
        <Mockup style={ width >= 1024 && { opacity }}>
          <Img fluid={data.mockup.childImageSharp.fluid} />
        </Mockup>
      </Background>
      <Illustration style={ width >= 1024 && { x, opacity: opacityIllustration }}>
        <Img fluid={data.illustration.childImageSharp.fluid} />
      </Illustration>
      <Text style={ width >= 1024 && { y, rotate, opacity: opacityText }}>
        {t('Cases:Eurobank:Tell story1')}
        <br/>
        {t('Cases:Eurobank:Tell story2')}
        <br/>
        {t('Cases:Eurobank:Tell story3')}
      </Text>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
    </Wrapper>
  );
};

export default EurobankMockup;
