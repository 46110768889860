import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";
import DetailsCases from "@components/svg/details-cases";

const Wrapper = tw.div`relative md:mt-50`;

const Logo = tw(motion.div)`
  w-180 ml-28
  lg:w-400 lg:ml-auto lg:mr-150 lg:mb-55
`;

const TextMobile = tw(motion.div)`ml-40 mr-20 text-sm text-second leading-18`;

const Heading = tw(TextMobile)`
  mb-50 -tracking-021
  sm:w-260
  lg:w-710 lg:h-260 lg:ml-110 lg:mr-0 lg:mb-0 lg:text-primary lg:text-2xl lg:leading-52
  lg:-tracking-024
`;

const IllustrationHandWrapper = tw(motion.div)`relative h-294 mb-18 sm:h-80 lg:h-400 lg:mb-0`;

const IllustrationHand = tw(Img)`
  absolute! -right-28 w-280
  sm:-top-200
  md:-top-220 md:-right-20 md:w-340
  lg:-top-125 lg:-right-350 lg:w-585
  xl:-right-210
`;

const MoreInfo = tw(TextMobile)`
  mb-40 -tracking-021
  sm:w-500
  lg:w-640 lg:h-228 lg:ml-auto lg:mr-95 lg:mb-120 lg:text-md lg:leading-30 lg:-tracking-027
`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute top-400 -left-90 w-160 transform
    sm:top-180 sm:-left-70 sm:w-160 sm:rotate-20
    lg:top-570 lg:left-580 lg:-scale-x-100 lg:rotate-90
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  lg:block lg:absolute lg:top-700 lg:-left-150 lg:w-320
  xl:top-675 xl:left-10 xl:w-360
`;

const DetailsCasesWrapper = tw.div`
  hidden
  lg:block lg:absolute lg:top-0 lg:left-0 lg:w-500
  xl:w-525
`;

const EurobankHeader = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "cases/eurobank.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      hand: file(relativePath: { eq: "cases/hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 60, sectionStart + 440], [0, 1]);
  const opacity = useTransform(scroll, [sectionStart + 230, sectionStart + 600], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 230, sectionStart + 600], [-100, 0]);
  const opacityDelay = useTransform(scroll, [sectionStart + 450, sectionStart + 700], [0, 1]);
  const xDelay = useTransform(scroll, [sectionStart + 450, sectionStart + 1100], [400, 0]);
  const opacityDesc = useTransform(scroll, [sectionStart + 1000, sectionStart + 1350], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper>
      <Logo style={ width >= 1024 && { scale }}>
        <Img fluid={data.logo.childImageSharp.fluid} />
      </Logo>
      <Heading style={ width >= 1024 && { x, opacity }}>
        {t('Cases:Eurobank:Heading')}
      </Heading>
      <IllustrationHandWrapper style={ width >= 1024 && { x: xDelay, opacity: opacityDelay }}>
        <IllustrationHand fluid={data.hand.childImageSharp.fluid} />
      </IllustrationHandWrapper>
      <MoreInfo style={ width >= 1024 && { opacity: opacityDesc }}>
        {t('Cases:Eurobank:More info')}
      </MoreInfo>
      <DetailsCasesWrapper>
        <DetailsCases />
      </DetailsCasesWrapper>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default EurobankHeader;
