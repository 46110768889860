import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";

export const Oval = tw.div`relative w-98 h-98 bg-oval rounded-full`;

export const OvalNumber = tw(motion.span)`absolute text-orange`;

export const OvalText = tw(motion.div)`
  font-pangramBold absolute text-md text-portfolioText leading-25
  lg:text-lg lg:leading-30
`;
