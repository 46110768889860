import React from "react";
import { useTranslation } from "react-i18next";
import { useTransform } from "framer-motion";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import { Oval, OvalNumber, OvalText } from "@components/cases/eurobank/ovals/oval";

const PeopleOval = styled(Oval)`
  left: 14%;
  ${tw`lg:left-66!`}
  
  ${up('sm')} {
    left: 5%;
  }
`;

const PeopleNumber = tw(OvalNumber)`-top-12 left-42 text-3xl leading-43 -tracking-054`;

const PeopleText = tw(OvalText)`bottom-10 left-32 -tracking-016 lg:-tracking-03`;

const EurobankPeople = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-5, 0]);
  const y = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-20, 0]);
  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [40, 42]);
  const top = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [28, -12]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <PeopleOval ref={Ref}>
      <PeopleNumber style={ width >= 1024 && { scale, top, left }}>
        {t('Cases:Eurobank:People number')}
      </PeopleNumber>
      <PeopleText style={ width >= 1024 && { scale, x, y }}>
        {t('Cases:Eurobank:People')}
      </PeopleText>
    </PeopleOval>
  );
};

export default EurobankPeople;
