import React from "react";
import styled from "styled-components";

import { dot, line } from '@utils/keyframes';
import { headerAnimationDuration } from "@utils/variables";

const StyledDot = styled.path<{ animationDelay: number }>`
  transform: scale(0);
  animation: ${dot} ${headerAnimationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const StyledLine = styled.path<{ animationDelay: number, rotate?: boolean }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transform: rotate(${({ rotate }) => rotate ? '180deg' : 0 });
  animation: ${line} ${headerAnimationDuration}s infinite;
  animation-delay: ${({ animationDelay }) => `${animationDelay}s` };
`;

const DetailsCases = () => (
  <svg viewBox="0 0 525 200" preserveAspectRatio="xMidYMid slice">
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" style={{ transform: 'matrix(1.1, 0, 0, 0.7, 382, 111)' }}>
      <StyledLine animationDelay={ 2 } rotate d=" M29.555999755859375,45.18000030517578 C29.555999755859375,45.18000030517578 -29.554000854492188,-45.18000030517578 -29.554000854492188,-45.18000030517578" />
    </g>
    <g style={{ transform: 'matrix(1, 0, 0, 1, 514, 47)' }}>
      <StyledDot animationDelay={ 2.8 } fill="#00a1d6" d=" M8.918000221252441,0.003000000026077032 C8.918000221252441,4.61299991607666 4.938000202178955,8.36299991607666 -0.0020000000949949026,8.36299991607666 C-4.921999931335449,8.36299991607666 -8.92199993133545,4.61299991607666 -8.92199993133545,0.003000000026077032 C-8.92199993133545,-4.617000102996826 -4.921999931335449,-8.366999626159668 -0.0020000000949949026,-8.366999626159668 C4.938000202178955,-8.366999626159668 8.918000221252441,-4.617000102996826 8.918000221252441,0.003000000026077032" />
    </g>
    <g style={{ transform: 'matrix(1, 0, 0, 1, 8, 163)' }}>
      <StyledDot animationDelay={ 3.6 } fill="#00a1d6" d=" M7.163000106811523,0.003000000026077032 C7.163000106811523,4.232999801635742 3.953000068664551,7.663000106811523 0.003000000026077032,7.663000106811523 C-3.9570000171661377,7.663000106811523 -7.1570000648498535,4.232999801635742 -7.1570000648498535,0.003000000026077032 C-7.1570000648498535,-4.236999988555908 -3.9570000171661377,-7.666999816894531 0.003000000026077032,-7.666999816894531 C3.953000068664551,-7.666999816894531 7.163000106811523,-4.236999988555908 7.163000106811523,0.003000000026077032" />
    </g>
    <g style={{ transform: 'matrix(1, 0, 0, 1, 203, 16)' }}>
      <StyledDot animationDelay={ 3 } fill="#ed3e2e" d=" M16.16900062561035,0.004999999888241291 C16.16900062561035,8.944999694824219 8.939000129699707,16.174999237060547 -0.0010000000474974513,16.174999237060547 C-8.930999755859375,16.174999237060547 -16.17099952697754,8.944999694824219 -16.17099952697754,0.004999999888241291 C-16.17099952697754,-8.944999694824219 -8.930999755859375,-16.174999237060547 -0.0010000000474974513,-16.174999237060547 C8.939000129699707,-16.174999237060547 16.16900062561035,-8.944999694824219 16.16900062561035,0.004999999888241291" />
    </g>
    <g strokeLinejoin="round" stroke="#761113" strokeWidth="0.25" style={{ transform: 'matrix(1.1, 0, 0, 0.7, 77, 64)' }}>
      <StyledLine animationDelay={ 2.4 } d=" M-48.51300048828125,35.69300079345703 C-48.51300048828125,35.69300079345703 48.516998291015625,-35.696998596191406 48.516998291015625,-35.696998596191406" />
    </g>
  </svg>
);

export default DetailsCases;
