import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import LayoutPortfolio from "@layouts/portfolio";
import SEO from "@components/seo";
import EurobankHeader from "@components/cases/eurobank/header";
import EurobankMockup from "@components/cases/eurobank/mockup";
import EurobankNumbers from "@components/cases/eurobank/numbers";
import EurobankInformation from "@components/cases/eurobank/information";

const EurobankPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <LayoutPortfolio
      width={width}
      scroll={scrollY}
      name={'eurobank'}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Eurobank' })}
        description={intl.formatMessage({ id: 'SEO.Description.Eurobank' })}
      />
      <EurobankHeader width={width} scroll={scrollY} />
      <EurobankMockup width={width} scroll={scrollY} />
      <EurobankNumbers width={width} scroll={scrollY} />
      <EurobankInformation width={width} scroll={scrollY} />
    </LayoutPortfolio>
  );
};

export default EurobankPage;
