import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import { Marks, Pages, Hours, People } from "@components/cases/eurobank/ovals";
import ArrowSecondary from "@components/arrows/secondary";

const Wrapper = tw.div`relative mb-280 sm:mb-180 lg:mb-275`;

const Heading = tw(motion.div)`
  ml-45 mb-100 text-xl leading-33 -tracking-016
  sm:mt-100 sm:-mb-50
  lg:mt-0 lg:ml-80 lg:-mb-65 lg:text-4xl lg:leading-56
`;

const Illustration = tw(motion.div)`
  absolute! -bottom-255 -right-32 w-300
  sm:-bottom-90 sm:right-0
  lg:-bottom-118 lg:right-40 lg:w-478
`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute -top-40 left-170 w-75 transform rotate-180
    lg:-top-140 lg:left-320 lg:w-140
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const IllustrationArrowWrapper = styled.div`
  ${tw`
    absolute -bottom-250 -left-10 w-80 transform -rotate-110 -scale-y-100
    sm:-bottom-80 sm:right-260 sm:left-auto
    lg:-bottom-180 lg:right-500 lg:w-150
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const EurobankNumbers = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [-120, 0]);

  const bottom = useTransform(scroll, [sectionStart + 250, sectionStart + 650], [-240, -118]);
  const right = useTransform(scroll, [sectionStart + 250, sectionStart + 650], [160, 40]);
  const opacityIllustration = useTransform(scroll, [sectionStart + 250, sectionStart + 650], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Heading style={ width >= 1024 && { opacity, x }}>
        {t('Cases:Eurobank:Project in numbers1')}
        <br/>
        {t('Cases:Eurobank:Project in numbers2')}
      </Heading>
      <ArrowWrapper>
        <ArrowSecondary />
      </ArrowWrapper>
      <Marks width={width} scroll={scroll} />
      <Pages width={width} scroll={scroll} />
      <Hours width={width} scroll={scroll} />
      <People width={width} scroll={scroll} />
      <Illustration style={ width >= 1024 && { bottom, right, opacity: opacityIllustration }}>
        <Img fluid={data.illustration.childImageSharp.fluid} />
      </Illustration>
      <IllustrationArrowWrapper>
        <ArrowSecondary />
      </IllustrationArrowWrapper>
    </Wrapper>
  );
};

export default EurobankNumbers;
