import React from "react";
import { useTranslation } from "react-i18next";
import { useTransform } from "framer-motion";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import { Oval, OvalNumber, OvalText } from "@components/cases/eurobank/ovals/oval";

const PagesOval = styled(Oval)`
  left: 20%;
  ${tw`
    mb-10
    lg:mb-46 lg:left-570!
  `}
  
  ${up('sm')} {
    left: 54%;
  }
`;

const PagesNumber = tw(OvalNumber)`
  -top-10 -left-32 text-7xl leading-58 -tracking-072
  lg:-top-25 lg:text-9xl lg:leading-77 lg:-tracking-096
`;

const PagesText = tw(OvalText)`
  -bottom-6 -left-5 -tracking-016
  lg:bottom-2 lg:-tracking-03
`;

const EurobankPages = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [28, 0]);
  const y = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-34, 0]);
  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-5, -32]);
  const top = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [10, -25]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <PagesOval ref={Ref}>
      <PagesNumber style={ width >= 1024 && { scale, top, left }}>
        {t('Cases:Eurobank:Pages number')}
      </PagesNumber>
      <PagesText style={ width >= 1024 && { scale, x, y }}>
        {t('Cases:Eurobank:Pages')}
      </PagesText>
    </PagesOval>
  );
};

export default EurobankPages;
