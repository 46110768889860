import React from "react";
import { useTranslation } from "react-i18next";
import { useTransform } from "framer-motion";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import { Oval, OvalNumber, OvalText } from "@components/cases/eurobank/ovals/oval";

const HoursOval = styled(Oval)`
  left: 52%;
  ${tw`
    -mb-28
    lg:mb-16 lg:left-280!
  `}
  
  ${up('sm')} {
    left: 28%;
  }
`;

const HoursNumber = tw(OvalNumber)`
  -top-14 left-18 text-5xl leading-48 -tracking-06
  lg:-top-22 lg:left-auto lg:-right-12 lg:text-7xl lg:leading-58 lg:-tracking-072
`;

const HoursText = tw(OvalText)`
  bottom-14 right-10 lg:-tracking-016
  lg:bottom-10 lg:right-4 lg:-tracking-03
`;

const EurobankHours = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-10, 0]);
  const y = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-25, 0]);
  const right = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-2, -12]);
  const top = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [15, -22]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <HoursOval ref={Ref}>
      <HoursNumber style={ width >= 1024 && { scale, top, right }}>
        {t('Cases:Eurobank:Hours number')}
      </HoursNumber>
      <HoursText style={ width >= 1024 && { scale, x, y }}>
        {t('Cases:Eurobank:Hours')}
      </HoursText>
    </HoursOval>
  );
};

export default EurobankHours;
