import React from "react";
import { useTranslation } from "react-i18next";
import { useTransform } from "framer-motion";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import { Oval, OvalNumber, OvalText } from "@components/cases/eurobank/ovals/oval";

const MarksOval = styled(Oval)`
  left: 40%;
  ${tw`
    mb-30
    lg:mb-64 lg:left-810!
  `}
  
  ${up('sm')} {
    left: 74%;
  }
`;

const MarksNumber = tw(OvalNumber)`
  bottom-45 -left-90 text-9xl leading-77 -tracking-096
  lg:bottom-40 lg:text-11xl lg:leading-108 lg:-tracking-135
`;

const MarksText = tw(OvalText)`
  bottom-14 -right-30 -tracking-016
  lg:-right-27 lg:-tracking-03
`;


const EurobankMarks = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-35, 0]);
  const y = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-20, 0]);
  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-125, -90]);
  const bottom = useTransform(scroll, [sectionStart + 50, sectionStart + 450], [-5, 40]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <MarksOval ref={Ref}>
      <MarksNumber style={ width >= 1024 && { scale, bottom, left }}>
        {t('Cases:Eurobank:Marks number')}
      </MarksNumber>
      <MarksText style={ width >= 1024 && { scale, x, y }}>
        {t('Cases:Eurobank:Marks')}
      </MarksText>
    </MarksOval>
  );
};

export default EurobankMarks;
